import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1180px;
  // width: calc(100% - 2px);
  margin: 0 auto var(--vertical_space);
  border-top: 1px solid #ddd;
  text-align: center;
  
  h1 {
    font-family: var(--font_section_title);
    font-size: 1.1rem;
    margin-top: 30px;
  }

  @media screen and (max-width: 1180px) {
    padding: 0;
  }
  @media screen and (min-width: 768px) {
    h1{
        font-size: 1.3rem;
    }
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: horizontal;
  max-width: 1180px;
  margin: 0 auto;
  margin-top: var(--vertical_space);

  @media screen and (min-width: 500px) and (max-width: 767px) {
    // grid-template-columns: 1fr auto;
  }
  @media screen and (min-width: 768px) {
    // grid-template-columns: auto 1fr auto;
  }
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
  @media screen and (max-width: 480px) {
    margin: 0
    width: 100%
  }
  @media screen and (max-width: 500px) {
    flex-direction: horizontal;
  }
  
  // div:first-child{
  //   margin-left: 0 !important;
  // }
  // div:last-child {
  //   margin-right: 0 !important;
  // }
`;
