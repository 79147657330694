import React from "react";
import {Wrapper, SectionWrapper} from "./FlexArea.styles";

const FlexArea = ({children}) => {

    return(
        <Wrapper>
            <SectionWrapper>
                {children}
            </SectionWrapper>
        </Wrapper>
    )
}

export default FlexArea;