import React from "react";
import {
    StyledImg,
    Wrapper,
    CardText,
    CardTextWrapper, CardTitle
} from "./NameCard.styles"

const NameCard = ({pic, name}) => {

    return(
        <Wrapper>
            <StyledImg
                image={pic.childImageSharp.gatsbyImageData}
                alt={name}
                width={720}
                style={{position: 'initial'}}
            />
            <CardTextWrapper>
                <CardTitle>{name}</CardTitle>
            </CardTextWrapper>
        </Wrapper>
    )
}

export default NameCard;