import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin-top: calc(-50px - var(--vertical_space));
  
  @media only screen and (min-width: 1100px) {
        margin-top: calc(-90px - var(--vertical_space));
  }
`

export const StyledImg = styled(GatsbyImage)`
  position: relative !important;
  display: inline-block;
  text-align: center;
  overflow: initial;
  
  width: 200px;
  height: 200px;
  margin: auto;
  transition: all 0.3s !important;
  border-radius: 50%;
  overflow: hidden;

  @media only screen and (min-width: 1100px) {
        height: 240px;
        width: 240px;
  }
`

export const CardTextWrapper = styled.div`
  
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
`

export const CardTitle = styled.h1`
  color: var(--THEME_BLACK);
  margin-bottom: 4px;
  padding: 0px;
  // font-size: 1.3rem;
  // font-family: var(--FONT_1);
  // font-weight: bold;
`

export const CardText = styled.p`
  font-size: 1rem;
  margin: -2px;
  padding: 0px;
`
