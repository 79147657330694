import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
   max-height: 300px;
   
   img{
    object-fit: none;
    object-position: 50% 10%;
    }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
`

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  // align-items: center;
  align-items: end;
  justify-content: center;
  height: calc(100% - 60px);
  width: 100%;

  h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 2rem;
    font-weight: 600;
    color: var(--THEME_WHITE);
    text-transform: none;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 4rem;
    }
  }
`
