import React from "react"
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout/Layout";
import {useIndexPageQuery} from "../hooks/useIndexPageQuery";
import NameCard from "../components/CARDS/NameCard/NameCard";
import InternalLinksArea from "../components/CARDS/LinkPictures/InternalLinkArea/InternalLinksArea";
import {OutlinedTextBox} from "../components/BOXES/OutlinedTextBox/OutlinedTextBox";
import HeroWithTitle from "../components/Heros/HeroWithTitle/HeroWithTitle";

const IndexPage = () => {

    const { mdx: {frontmatter : indexData}} = useIndexPageQuery();

    return(
        <Layout>
            <HeroWithTitle img={indexData.image}/>
            <NameCard name={indexData.nameCard_name} pic={indexData.nameCard_pic} />
            <OutlinedTextBox text={indexData.picture_text_section.text}/>
            <InternalLinksArea links_area_list={indexData.internal_links_area}/>
        </Layout>
    )

}
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <SEO title="Home" />

export default IndexPage
