import React from "react";
import InternalLink from "../InternalLink/InternalLink";
import FlexArea from "../../../POSITIONING/FlexArea/FlexArea";

const InternalLinksArea = ({links_area_list, item_class_name}) => {

    return(
        <FlexArea>
            {links_area_list.map(item =>
            <InternalLink
                image={item.image}
                objectFit={item.objectFit}
                link={item.link}
                text={item.text}
                key={item.link}
                item_class_name={item_class_name}
                style={{
                    width: `calc( 100%/${links_area_list.length})`,
                // width:`calc( 100%/${links_area_list.length} - 1% )`,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxHeight: '100px',
                    maxWidth: '50%'
                }}
            />
            )}
        </FlexArea>
    )
}

export default InternalLinksArea;