import React from "react";
import {getImage} from "gatsby-plugin-image";
import {Wrapper, HeaderWrapper, StyledImg} from "./HeroWithTitle.styles";

const HeroWithTitle = ({img, title}) => {

    const imageData = getImage(img.childImageSharp.gatsbyImageData);

    return(
        <Wrapper className="fullwidth_correction">
            <StyledImg image={imageData} alt={`Hintergrund`}/>
            <HeaderWrapper>
                <h1>
                    {title}
                </h1>
            </HeaderWrapper>
        </Wrapper>
    )
}

export default HeroWithTitle;