import React from "react";
import {Wrapper, ContentDiv} from "./OutlinedTextBox.styles";

export const OutlinedTextBox = ({text}) => {

    return(
        <Wrapper>
            <ContentDiv>
                <p>
                    {text}
                </p>
            </ContentDiv>
        </Wrapper>
    )
}