import styled from "styled-components";

export const Wrapper = styled.div`
    max-height: 200px;
    width: calc(100% - 2px);
    display: flex;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--THEME_PRIMARY1);
    border-radius: 4px;
    margin: 0 auto var(--vertical_space);
    background-color: var(--THEME_WHITE);
    
    animation: 2s ease-out 0s 1 slideInFromBottom;
    @keyframes slideInFromBottom {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

export const ContentDiv = styled.div`
    // text-transform: uppercase;
    display: contents;
    
    p{
        margin: auto;
        padding: 20px;
        text-align: center;
    }
`;