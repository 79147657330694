import React from "react";
import {StyledImg, Wrapper, LinkImageText, LinkImageTextWrapper} from "../Link.styles"
import {Link} from "gatsby";

const InternalLink = ({image, link, text, objectFit, item_class_name, style}) => (

    <Wrapper className={item_class_name} style={style}>
        <StyledImg
            image={image.childImageSharp.gatsbyImageData}
            alt={text}
            width={720}
            imgStyle={{objectFit: objectFit}}
        />
        <Link to={link}>
            <LinkImageTextWrapper>
                <LinkImageText>
                    {text}
                </LinkImageText>
            </LinkImageTextWrapper>
        </Link>
    </Wrapper>
)

export default InternalLink;