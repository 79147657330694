import {useStaticQuery, graphql} from "gatsby";


export const useIndexPageQuery = () => {
    const data = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          title
        }
      }
      mdx(frontmatter: {individual_type: {eq: "index-page"}}) {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          nameCard_name
          nameCard_pic {
            childImageSharp {
              gatsbyImageData
            }
          }
          internal_links_area {
            link
            text
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            objectFit
          }
          picture_text_section {
            img {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
            text
          }
        }
      }
    }`)

    return data;
}