import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

// Used by following components:
// ExternalLink
// InternalLink

export const Wrapper = styled.div`
  max-height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  :hover img {
    transform: scale(1.1);
    filter: blur(2px);
  }

  @media (max-width: 500px) {
        max-width: 100% !important;
        width: 100%;
  }
  @media (min-width: 768px) {
    max-height: 200px;
  }
  
  
`

export const StyledImg = styled(GatsbyImage)`
  position: 'initial';
  width: 100%;
  height: 100%;
  
  img {
    transition: all 1s ease !important;
  }
`

export const LinkImageTextWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
   
  transition: all 1s ease;
  :hover{
    background-color: var(--THEME_PRIMARY1_OP-60);
    transform: scale(1.1);
  }
`

export const LinkImageText = styled.p`
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  margin: 0px;
  padding: 0px;
  
  font-family: var(--FONT_2);
  font-weight: 300;
  color: var(--THEME_WHITE);
  letter-spacing: 1px;
`
